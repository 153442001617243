import { Text } from '@chakra-ui/react';

type ListEmptyTextProps = {
  text: string;
};

const ListEmptyText = ({ text }: ListEmptyTextProps) => {
  return (
    <Text textAlign='center' color='gray.500' fontSize='sm' my='5'>
      {text}
    </Text>
  );
};

export default ListEmptyText;
